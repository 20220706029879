import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    window.initWallpaper = (prog = false) => {
      document.body.classList.add('skin');
      document.body.classList.add('skin-opti');
      if(prog === false || typeof prog !== 'boolean') {
        const wallpaper = document.getElementById('optidigital-adslot-Wallpaper');
        const billboard = document.getElementById('optidigital-adslot-Billboard_1');
        if(wallpaper !== null && billboard !== null) wallpaper.appendChild(billboard);
      } else {
        document.body.classList.add('skin-prog');
      }
      window.scrollTo(0,0);
    }

    const layout = document.querySelector(".layout");

    if(!document.body.classList.contains("no_ad")) {
      if(layout && layout.classList.contains("layout-story")) this._insertInreads();

      const categories = this.data.get("categories");
      const tags = this.data.get("tags");
      const noAd = this.data.get("no-ad");
      const articleId = this.data.get("article-id");
      const adUnit = this.data.get("ad-unit");

      optidigitalQueue.cmd.push(function() {
        if(categories !== null) optidigitalToolbox.setPageTargeting("categories", "Monde");
        if(tags !== null) optidigitalToolbox.setPageTargeting("tags", JSON.parse(tags));
        if(articleId !== null) optidigitalToolbox.setPageTargeting("article_id", articleId);
        if(noAd !== null) optidigitalToolbox.setPageTargeting("no_ad", noAd);
        optidigitalToolbox.infiniteScrollRefresh(adUnit);
      });
    }
  }

  _insertInreads() {
    const container = document.querySelector(".field-item");
    const tags = container.children;

    let paragraphsCount = 0;
    let mobileInsertionsCount = 0;
    let desktopInsertionsCount = 0;
    let desktopCurrentHeight = 0;
    let mobileCurrentHeight = 0;
    const desktopThreshold = 700;
    const mobileThreshold = 600;
    const maxInreads = 12;

    if(container.children.length > 2) {
      Array.from(tags).forEach((child, index) => {
        if(paragraphsCount == 1 && child.tagName === "P") {
          mobileInsertionsCount++;
          this._createInsertion(child, mobileInsertionsCount, "mobile");

          desktopInsertionsCount++;
          this._createInsertion(child, desktopInsertionsCount, "desktop");
        } else if(paragraphsCount > 1) {
          mobileCurrentHeight += child.offsetHeight;
          desktopCurrentHeight += child.offsetHeight;

          if(mobileCurrentHeight >= mobileThreshold && child.tagName === "P" && mobileInsertionsCount < maxInreads) {
            mobileInsertionsCount++;
            this._createInsertion(child, mobileInsertionsCount, "mobile");
            mobileCurrentHeight = 0;
          }

          if(desktopCurrentHeight >= desktopThreshold && child.tagName === "P" && desktopInsertionsCount < maxInreads) {
            desktopInsertionsCount++;
            this._createInsertion(child, desktopInsertionsCount, "desktop");
            desktopCurrentHeight = 0;
          }
        }

        if(child.tagName === "P") paragraphsCount++;
      });
    }
  }

  _createInsertion(p, index, device) {
    const div = document.createElement("div");
    div.style.display = "none";
    if(device == "desktop") {
      div.classList.add("ad-content", "mt-5");
      div.classList.add(`Content_${index}`);
    } else {
      div.classList.add("ad-mobile", "mt-5");
      div.classList.add(`Mobile_Pos${index}`);
    }
    if(p.nextSibling) {
      p.parentNode.insertBefore(div, p.nextSibling);
    } else {
      p.parentNode.appendChild(div);
    }
  }

  disconnect() {
  }
}
