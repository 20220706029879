import { Controller } from "@hotwired/stimulus"
import axios from "axios";

export default class extends Controller {
  static targets = ["button", "email", "form", "newsletterId", "provider", "response", "slate"];

  submit(event) {
    event.preventDefault();
    const csrfToken = document.querySelector("[name='csrf-token']").getAttribute("content");
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const data = {
      email: this.emailTarget.value,
      authenticity_token: csrfToken
    }

    if(this.emailTarget.value == "" || !emailRegex.test(this.emailTarget.value)) {
      this.responseTarget.classList.remove("success");
      this.responseTarget.classList.add("error");
      this.responseTarget.textContent = "Vous devez saisir une adresse email valide.";
    } else {
      if(this.hasProviderTarget && this.hasNewsletterIdTarget) {
        data["newsletter_provider"] = this.providerTarget.value;
        data["newsletter_id"] = this.newsletterIdTarget.value;

        axios.post("/services/newsletters", data)
          .then(response => {
            if(response.data.statusText == "OK") {
              this.emailTarget.classList.remove("d-block");
              this.emailTarget.classList.add("d-none");
              this.buttonTarget.classList.add("d-none");
              this.responseTarget.classList.remove("error");
              this.responseTarget.classList.add("success");
              this.responseTarget.textContent = "Merci ! Vous allez recevoir sous peu un e-mail de confirmation, n'oubliez pas de valider votre inscription.";
            } else {
              this.emailTarget.classList.remove("d-block");
              this.emailTarget.classList.add("d-none");
              this.buttonTarget.classList.add("d-none");
              this.responseTarget.classList.remove("success");
              this.responseTarget.classList.add("error");
              this.responseTarget.textContent = "Désolé, il y a eu une erreur lors de votre inscription, ou vous êtes déjà inscrit·e à cette newsletter.";
            }
          })
          .catch(error => {
            if (error.response) {
              console.log("Error Response Data:", error.response.data);
              console.log("Error Response Status:", error.response.status);
              console.log("Error Response Headers:", error.response.headers);
            } else if (error.request) {
              console.log("Error Request:", error.request);
            } else {
              console.log("Error Message:", error.message);
            }
            console.log("Error Config:", error.config);
          });
      }
    }
  }
}